<template>
  <div id="basic_info">
    <div  class="repay_pwd pay_pwd_form">
      <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="110px" class="demo-ruleForm">
        <el-form-item label="手机号："  prop="ph_num">
          <div class="personal_info">
            {{infoForm.ph_num}}
          </div>
        </el-form-item>
        <el-form-item label="短信验证码："  prop="v_code" class="v_code">
          <el-input placeholder="请输入短信验证码" autocomplete="new-password" v-model="infoForm.v_code" class="v_code_input"></el-input>
          <div v-if="smsCode" class="v_code_btn" @click="smsSend(infoForm.ph_num)">获取</div>
          <div v-else class="v_code_btn" id="smsFalse" >{{ smsTime }}s</div>
        </el-form-item>
        <el-form-item label="登陆密码："  prop="pwd">
          <el-input placeholder="请输入新登陆密码" autocomplete="new-password" v-model="infoForm.pwd" show-password ></el-input>
        </el-form-item>
        <el-form-item label="确认密码："  prop="re_pwd">
          <el-input placeholder="请确认登陆密码" autocomplete="new-password" v-model="infoForm.re_pwd" show-password></el-input>
        </el-form-item>
      </el-form>
      <div class="submit_btn" @click="submitForm('infoForm')">确认提交</div>
    </div>
  </div>
</template>
<script>
import {apiSmsSend,apiReset,apiuserInfo} from "@/request/API";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    var validatePwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入登陆密码'))
      } else if (value !== this.infoForm.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      //表单
      infoForm:{
        ph_num:this.$store.state.userData.telphone,
        v_code:'',
        pwd:'',
        re_pwd:''
      },
      //表单验证
      rules: {
        v_code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        pwd: [
          { required: true, message: '请输入新登陆密码', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '密码由8到16位数字和字母组成' }
        ],
        re_pwd: [
          { required: true, validator: validatePwd, trigger: 'blur' }
        ]
      },
      //获取验证码
      smsCode:true,
      smsTime:60
    }
  },
  methods: {
    /**
     * 获取验证码
     * @param phone
     */
    smsSend(phone){
      apiSmsSend({
        phone:phone,
        codeType:0
      }).then(res => {
        this.$message({
          type:"success",
          center:true,
          message:"发送成功"
        })
        this.smsCode = false
      }).catch(err=>{
        this.$message({
          type:"error",
          center:true,
          message:err.msg
        })
      })

    },
    //表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiReset({
            phone:this.infoForm.ph_num,
            pwd:this.infoForm.pwd,
            pwd_confirm:this.infoForm.re_pwd,
            smsCode:this.infoForm.v_code,
          }).then(res => {
            this.$message({
              message:"修改密码成功",
              center:true,
              type:"success"
            })
          }).catch(err=>{
            this.$message({
              message:err.msg,
              center:true,
              type:"error"
            })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //获取 支付密码 信息
    getBasicInfo(){
      apiuserInfo({
        action:'base'
      }).then(res=>{
        this.infoForm.ph_num = res.data.telphone
      })
    },
    //获取验证码冷却
    countdownTime(){
      this.smsTime--
      if(this.smsTime === 0){
        this.smsCode = true
      }
    }
  },
  mounted () {
    this.getBasicInfo()
  },
  watch: {
    smsCode(val, oldVal){//普通的watch监听
      if(!val){
        this.smsTime = 60
        this.countdown = setInterval(this.countdownTime, 1000);
      }else{
        clearInterval(this.countdown)
      }
    },
  },
  computed: {

  },
  created() {
    if (localStorage.getItem('loginStatus') == "true") {
      this.infoForm.ph_num = JSON.parse(localStorage.getItem('userData')).telphone
    }
  }
}
</script>


<style lang='less' scoped>
#basic_info{
  padding: 60px 0 0 256px;
  display: flex;
  flex-direction: column;
  .pay_pwd_form{
    /deep/.el-input{
      width: 250px;
    }
    /deep/.el-input__inner{
      width: 250px;
      height: 40px;
      background: #F9F9F9;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    /deep/.is-error .el-input__inner{
      border-color: #F56C6C;
    }
    .v_code_input,.v_code_input /deep/.el-input__inner{
      width: 180px;
    }
    .v_code_btn{
      margin: 0 0 0 10px !important;
      width: 60px;
      height: 36px;
      line-height: 36px;
      background: #434ED6;
      border-radius: 10px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #FFFFFF;
      cursor: pointer;
      text-align: center;
      display: inline-block;
    }
    #smsFalse{
      background: #f2f2f2;
      cursor: auto;
    }
    /deep/.el-form-item__label{
      text-align: left;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      padding: 0;
    }
    /deep/.el-form-item .el-form-item__label{
      padding: 0 0 0 12px;
    }
    /deep/.is-required .el-form-item__label{
      padding:0;
    }
    .personal_info{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
      padding: 0 0 0 20px;
    }
    .submit_btn{
      margin: 29px 0 0 82px;
      width: 200px;
      height: 40px;
      line-height: 40px;
      background: #434ED6;
      border-radius: 28px;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }
  }

}
</style>
